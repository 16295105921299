import { createRouter, createWebHashHistory } from "vue-router";
import { isLogin } from "@/utils/auth";
import Layout from "@/layout/IndexView.vue";

const routes = [
  {
    path: "/login",
    name: "LoginView",
    component: () => import("@/views/user/LoginView.vue"),
  },
  {
    path: "/",
    redirect: "/bi/month-report",
  },
  {
    path: "/bi",
    name: "bi",
    component: Layout,
    redirect: "/bi/month-report",
    meta: { title: "月报表" },
    children: [
      {
        path: "month-report",
        name: "MonthReport",
        component: () => import("@/views/bi/MonthReport"),
        meta: {
          title: "仪表盘",
          auth: true,
          menuId: 11,
          parentId: 10,
        },
      },
      {
        path: "repair-order",
        name: "RepairOrder",
        component: () => import("@/views/bi/RepairOrder.vue"),
        meta: {
          title: "维修单",
          auth: true,
          menuId: 13,
          parentId: 10,
        },
      },
      {
        path: "project-outlay",
        name: "ProjectOutlayList",
        component: () => import("@/views/bi/ProjectOutlayList"),
        meta: {
          title: "开支汇总",
          auth: true,
          menuId: 15,
          parentId: 10,
        },
      },
    ],
  },
  {
    path: "/worker",
    name: "worker",
    component: Layout,
    meta: { title: "工人" },
    children: [
      {
        path: "list",
        name: "WorkerList",
        component: () => import("@/views/worker/WorkerList"),
        meta: {
          title: "工人管理",
          auth: true,
          menuId: 31,
          parentId: 30,
        },
      },
      {
        path: "attendance",
        name: "WorkerAttendance",
        component: () => import("@/views/worker/WorkerAttendance"),
        meta: {
          title: "考勤记录",
          auth: true,
          menuId: 32,
          parentId: 30,
        },
      },
      {
        path: "material",
        name: "WorkerMaterial",
        component: () => import("@/views/worker/WorkerMaterial"),
        meta: {
          title: "采购记录",
          auth: true,
          menuId: 33,
          parentId: 30,
        },
      },
      {
        path: "repair-process",
        name: "WorkerRepairProcess",
        component: () => import("@/views/worker/WorkerRepairProcess"),
        meta: {
          title: "维修记录",
          auth: true,
          menuId: 34,
          parentId: 30,
        },
      },
    ],
  },
  {
    path: "/project",
    name: "project",
    component: Layout,
    meta: { title: "项目" },
    children: [
      {
        path: "list",
        name: "ProjectList",
        component: () => import("@/views/project/ProjectList"),
        meta: {
          title: "项目管理",
          auth: true,
          menuId: 51,
          parentId: 50,
        },
      },
      {
        path: "outlay",
        name: "ProjectOutlay",
        component: () => import("@/views/project/ProjectOutlay"),
        meta: {
          title: "项目开支",
          auth: true,
          menuId: 52,
          parentId: 50,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.fullPath === "/login" && isLogin()) {
    next("/");
  } else if (to.meta.auth && !isLogin()) {
    next("/login");
  } else {
    next();
  }
});

export default router;
