/**
 * 构造树型结构数据 - 屏蔽没有子类的数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} removeParent 移除无children的父数据 默认 false
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, removeParent, parentId, children) {
  let config = {
    id: id || "id",
    removeParent: removeParent || false,
    parentId: parentId || "parentId",
    childrenList: children || "children",
  };

  let childrenListMap = {};
  let nodeIds = {};
  let tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }
  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }
  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  let newTree = [];
  tree.forEach((item) => {
    if (item[config.childrenList] !== undefined) {
      newTree.push(item);
    }
  });
  if (removeParent) {
    return newTree;
  } else {
    return tree;
  }
}

/**
 * 构造树型结构数据 - 保存所有数据包含没有子类数据
 * @param data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} removeParent 移除无children的父数据 默认 false
 * @returns {*[]}
 */
export function handleTreeData(data, id, removeParent) {
  let config = {
    id: id || "id",
    removeParent: removeParent || false,
    parentId: "parentId",
    childrenList: "children",
  };

  let childrenListMap = {};
  let nodeIds = {};
  let tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }
  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }
  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  let newTree = [];
  tree.forEach((item) => {
    newTree.push(item);
  });
  if (removeParent) {
    return newTree;
  } else {
    return tree;
  }
}
