const isLogin = () => {
  return !!localStorage.getItem("access_token");
};

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

const setAccessToken = (accessToken) => {
  localStorage.setItem("access_token", accessToken);
};

const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

const setRefreshToken = (refreshToken) => {
  localStorage.setItem("refresh_token", refreshToken);
};

const clearAccessToken = () => {
  localStorage.removeItem("access_token");
};

const clearRefreshToken = () => {
  localStorage.removeItem("refresh_token");
};

export {
  isLogin,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  clearAccessToken,
  clearRefreshToken,
};
