import request from "../plugins/request";

/**
 * 用户登录
 */
export const login = (data) => {
  return request({
    url: "/admin/login",
    method: "post",
    data,
  });
};

/**
 * 用户修改密码
 */
export const userChangePassword = (data) => {
  return request({
    url: "/admin/password/change",
    method: "post",
    data,
  });
};

/**
 * 获取当前管理员权限
 */
export function userAuth() {
  return request({
    url: "/admin/auth",
    method: "get",
  });
}
